<template>
    <router-view/>
</template>

<script setup>



</script>


<style lang="scss">

@import url('https://fonts.googleapis.com/css2?family=Cabin:wght@500&family=Source+Sans+3:wght@300;400;700;900&display=swap');
@import '~normalize.css';
@import '@/style/main.scss';

</style>